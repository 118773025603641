import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { POSTS } from '../constants'
import { PostDisapprovalFormInput } from '../types'

const updatePostDisapproval = async (data: PostDisapprovalFormInput) => {
  await fetchAPI({
    method: 'put',
    path: `/posts/${data.id}/disapprove`,
    data: { reason: data.reason },
  })
}

export const useUpdatePostDisapproval = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updatePostDisapproval, {
    onSuccess: async () => {
      await queryClient.refetchQueries([POSTS])

      toast({
        description: t('views.admin.posts.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
