import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import { Modal } from '@/components/shared/Modal'
import { FormControl } from '@/features/forms/components'
import { useUpdatePostDisapproval } from '@/features/posts/mutations'

import { t } from '@/lib/helpers'
import { Post, PostDisapprovalFormInput } from '../types'

type Props = {
  isOpen: boolean
  onClose: () => void
  data: Post
}

export const DisapprovePostModal = (props: Props) => {
  const { isOpen, onClose, data } = props
  const [selectedReason, setSelectedReason] = useState<string>('')

  const {
    mutate: updatePostDisapproval,
    isSuccess: isUpdatePostDisapprovalSuccess,
  } = useUpdatePostDisapproval()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reason: '',
    },
  })

  const options = [
    {
      id: '1',
      name: t('views.admin.posts.disapprove_form.category_difference'),
    },
    {
      id: '2',
      name: t('views.admin.posts.disapprove_form.similar_post'),
    },
    {
      id: '3',
      name: t('views.admin.posts.disapprove_form.other'),
    },
  ]

  const onHandleDisapprove = (payload: PostDisapprovalFormInput) => {
    const disapproveReason =
      selectedReason === '3'
        ? payload.reason
        : options.find((opt) => opt.id === selectedReason)?.name
    updatePostDisapproval({ id: data.id, reason: disapproveReason })
  }

  const handleReasonChange = (value: string) => {
    setSelectedReason(value)
    if (value !== '3') {
      setValue('reason', options.find((opt) => opt.id === value)?.name)
    } else {
      setValue('reason', '')
    }
  }

  useEffect(() => {
    if (isUpdatePostDisapprovalSuccess) {
      setSelectedReason('')
      onClose()
    }
  }, [isUpdatePostDisapprovalSuccess])

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="500px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px', textAlign: 'center' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.posts.disapprove_form.title')}
        </Text>
        <form onSubmit={handleSubmit(onHandleDisapprove)}>
          <Grid sx={{ gridTemplateColumns: '1fr', gap: '32px' }}>
            <FormControl
              id="reason"
              label={t('views.admin.posts.disapprove_form.reason')}
              isRequired
              errorMsg={errors.reason?.message}
            >
              <RadioGroup
                value={selectedReason}
                onChange={handleReasonChange}
                name="reason"
              >
                <Stack>
                  {options.map((reason) => (
                    <Radio key={reason.id} value={reason.id}>
                      {reason.name}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
              {selectedReason === '3' && (
                <Input
                  size="lg"
                  name="reason"
                  ref={register}
                  sx={{
                    mt: '16px',
                  }}
                />
              )}
            </FormControl>
            <Button type="submit" sx={{ h: '48px', minW: '100px', mt: '20px' }}>
              {t('views.admin.posts.disapprove_form.disapprove')}
            </Button>
          </Grid>
        </form>
      </Box>
    </Modal>
  )
}
