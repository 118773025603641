import { Box, HStack, Image, Text } from '@chakra-ui/react'
import { get } from 'lodash'
import React, { useMemo, useState } from 'react'

import { Post } from '@/features/posts/types'
import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableDisapproveButton,
  TableHideButton,
  TableApproveButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { DisapprovePostModal } from '@/features/posts/components/DisapprovePostModal'
import { useDeletePost, useUpdatePost } from '@/features/posts/mutations'
import { ApprovePostModal } from '@/features/posts/components/ApprovePostModal'

type Props = {
  data: Post[]
}

export const PostTable = ({ data }: Props) => {
  const { mutate: deletePost } = useDeletePost()
  const { mutate: updatePost } = useUpdatePost()

  const [selectedPost, setSelectedPost] = useState<Post | null>(null)
  const [isDisapproveModalOpen, setIsDisapproveModalOpen] = useState(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.posts.index.image'),
        accessor: 'images',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')
          const url = get(value, '[0].url')
          let img
          if (url) {
            img = (
              <a href={url} target="_blank">
                <Image src={url} sx={{ boxSize: '80px' }} />
              </a>
            )
          } else {
            img = null
          }

          return <Box w="60px">{img}</Box>
        },
      },
      {
        Header: t('views.admin.posts.index.user_name'),
        accessor: 'user.nickname',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.posts.index.message'),
        accessor: 'message',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return <Text w="50px">{value}</Text>
        },
      },
      {
        Header: t('views.admin.posts.index.tag'),
        accessor: 'tag',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return <Text w="60px">{value}</Text>
        },
      },
      {
        Header: t('views.admin.posts.index.posted_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.posts.index.status'),
        accessor: 'approved',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')
          const reason = get(cellProps, 'row.original.disapproved_reason')
          const status = value
            ? t('views.admin.posts.index.approved')
            : reason
            ? t('views.admin.posts.index.denied')
            : t('views.admin.posts.index.pending')
          return (
            <>
              <Text
                sx={{
                  color: value ? 'green.500' : reason ? 'red.500' : 'gray.500',
                  bg: value ? 'green.100' : reason ? 'red.100' : 'gray.100',
                  w: '110px',
                  p: 2,
                  rounded: 'md',
                  textAlign: 'center',
                }}
              >
                {status}
              </Text>
              {reason && (
                <Text
                  p={2}
                  w="110px"
                  overflowX="auto"
                  whiteSpace="nowrap"
                  isTruncated={false}
                >
                  {reason}
                </Text>
              )}
            </>
          )
        },
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original') as Post
          const { id, hide } = row
          const hideText = hide ? 'unhide' : 'hide'

          return (
            <HStack spacing="2px">
              <TableHideButton
                ariaLabel={`${hideText} post`}
                onClick={() => {
                  if (confirm(t(`views.admin.posts.table.${hideText}_post`))) {
                    updatePost(id)
                  }
                }}
                isHide={hide}
              />
              <TableApproveButton
                title="approve post"
                ariaLabel="approve post"
                onClick={() => {
                  handleApprove(row)
                }}
              />
              <TableDisapproveButton
                title="deny post"
                ariaLabel="disapprove post"
                onClick={() => {
                  handleDisapprove(row)
                }}
              />
              <TableTrashButton
                ariaLabel={'delete post'}
                onClick={() => {
                  if (confirm(t('views.admin.posts.table.confirm_delete'))) {
                    deletePost(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  const handleDisapprove = (row: Post) => {
    setSelectedPost(row)
    setIsDisapproveModalOpen(true)
  }

  const handleApprove = (row: Post) => {
    setSelectedPost(row)
    setIsApproveModalOpen(true)
  }

  return (
    <>
      <DataTable data={memoizedData} columns={columns} />
      <ApprovePostModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        data={selectedPost}
      />
      <DisapprovePostModal
        isOpen={isDisapproveModalOpen}
        onClose={() => setIsDisapproveModalOpen(false)}
        data={selectedPost}
      />
    </>
  )
}
