import React, { useState, useEffect } from 'react'

import { Modal } from '@/components/shared/Modal'
import {
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  useTheme,
  FormLabel,
} from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'

import { useUpdatePostApproval } from '@/features/posts/mutations'
import { t } from '@/lib/helpers'
import { Post } from '../types'

type Props = {
  isOpen: boolean
  onClose: () => void
  data: Post
}

export const ApprovePostModal = (props: Props) => {
  if (!props.data || !props.isOpen) return null
  const { isOpen, onClose, data } = props

  const { mutate: updatePostApproval, isSuccess: isApproveSuccess } =
    useUpdatePostApproval()

  const [error, setError] = useState('')
  const [selectedImages, setSelectedImages] = useState(
    data?.images?.filter((image) => image.approved).map((image) => image.id),
  )
  const theme = useTheme()

  useEffect(() => {
    if (isApproveSuccess) {
      onClose()
    }
  }, [isApproveSuccess])

  const handleApproveImages = () => {
    if (data?.images?.length > 0 && selectedImages.length === 0) {
      setError(t('views.admin.posts.approve_form.error'))
      return
    }
    const approve_image_ids = selectedImages.map((id) => id)
    updatePostApproval({
      id: data.id,
      approve_image_ids,
    })
  }

  if (data?.images?.length === 0) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} maxW="300px" withCloseButton>
        <Box sx={{ p: '32px', pb: '40px', textAlign: 'center' }}>
          <Text textStyle="modalHeader" mb="20px">
            {t('views.admin.posts.approve_form.title')}
          </Text>
          <Button
            onClick={handleApproveImages}
            sx={{ h: '60px', minW: '150px', mt: '40px' }}
          >
            {t('views.admin.posts.approve_form.submit')}
          </Button>
        </Box>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px', textAlign: 'center' }}>
        <Text textStyle="modalHeader" mb="50px">
          {t('views.admin.posts.approve_form.image_title')}
        </Text>
        {error && (
          <FormLabel
            sx={{ fontSize: '16px', fontWeight: 'bold', color: 'red' }}
          >
            {error}
          </FormLabel>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {data?.images.map((image, index) => {
            const isSelected = selectedImages.includes(image.id)
            return (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  border: isSelected
                    ? `4px solid ${theme.colors.brand[500]}`
                    : '4px solid transparent',
                  opacity: isSelected ? 1 : 0.5,
                  boxShadow: isSelected ? '0 0 10px 10px #dadada' : 'none',
                  maxW: '300px',
                }}
                onClick={() => {
                  setSelectedImages((prev) => {
                    if (prev.includes(image.id)) {
                      return prev.filter((id) => id !== image.id)
                    }
                    return [...prev, image.id]
                  })
                }}
              >
                <Image
                  src={image.url}
                  sx={{
                    width: '100%',
                    borderRadius: '6px',
                    filter: isSelected ? 'none' : 'grayscale(100%)',
                  }}
                  draggable="false"
                />
                {isSelected && (
                  <Icon
                    as={FiCheck}
                    sx={{
                      fontSize: '28px',
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      color: theme.colors.white,
                      background: theme.colors.brand[500],
                      borderRadius: '50%',
                      padding: '2px',
                    }}
                  />
                )}
              </Box>
            )
          })}
        </Box>
        <Button
          onClick={handleApproveImages}
          sx={{ h: '60px', minW: '120px', mt: '40px' }}
          disabled={selectedImages.length === 0}
        >
          {t('views.admin.posts.approve_form.submit')}
        </Button>
      </Box>
    </Modal>
  )
}
